import React from "react"
import { Styled } from "theme-ui"

export default function Bio() {
  return (
    <>
      Welcome to yet another coding blog. I am a backend engineer who is passionate about writing software that scales.
      I will use this space to share my tech ramblings, as I navigate through different technologies.
      I have helped built data-intensive pipelines and event sourcing systems, and as I learn through more of the same
      and hopefully other new fascinating tech, I will try to share that experience.
      Questions? Comments? Concerns? Reach out to me at blog@haaris.dev
    </>
  )
}
